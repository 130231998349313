body{
  background: #DEE5E5 !important; 
  background-attachment: fixed;
}

/* TextField Border*/
fieldSet.MuiOutlinedInput-notchedOutline{
  border-color: black;
}

/*Secondary Button */
button.secondary {
  background-color: white;
  color: black;
}

.createDialog .MuiTextField-root{
  width: 280px;
}

.select.MuiFormLabel{
  top: -7px
}

.stackContainer {
  display: inline-block;
}

.MuiAccordion-root{
  background-color: white;
  box-shadow: none !important;
}

.Mui-expanded{
  margin: 0 auto !important;
  box-shadow: none !important;
}

/* media queries*/
@media only screen 
  and (min-device-width: 320px)
  and (max-device-width: 568px) {
    .stackContainer {
      display: inline !important;
   }
}